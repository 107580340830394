import { dossierPatientModel } from '@/models/dossier-response-model'
import { Subscription } from 'rxjs'
import { Component, Vue } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Alert from '@/components/shared/Alert.vue'
import { ErrorService } from '@/services/error.service'
import { AxiosResponse } from 'axios'
import { PodiatryObservationModel } from '@/models/podiatry-observation-model'
import { AuthService } from '@/services/auth-service'
import Commons from '@/components/shared/Helpers/commons'
import { PodoService } from '@/services/patient-podo-service'
import EvaluationInitiale from '@/views/Patients/Overview/EvaluationInitiale/EvaluationInitiale.vue'
import { consultationGenre, statusOFAS } from '../../../Dossier/Constants'
import AppointmentSelector from '@/components/shared/AppointmentSelector/AppointmentSelector.vue'
import { AppointmentForConsultationResponseModel } from '@/models/agenda-model'
import { AgendaService } from '@/services/agenda-service'
import Confirm from '@/components/shared/Confirm/confirm.vue'

@Component({
  components: {
    AppointmentSelector,
    Confirm,
    ValidationObserver,
    ValidationProvider,
    Alert,
    EvaluationInitiale
  }
})
export default class Observation extends Vue {
  private subscriptions: Subscription[] = []
  private selectedDossier!: dossierPatientModel
  private podoService = PodoService.getInstance()
  private userService = AuthService.getInstance()
  private agendaService = AgendaService.getInstance()
  public observation: PodiatryObservationModel | any = {}
  public isLoading = false
  public errorMessages: string[] = []
  public userFullName = ''
  public genreObservation = consultationGenre
  public appointments: AppointmentForConsultationResponseModel[] = []
  private readyAppointments = false
  public ready = false

  public showConfirm = false
  public routeNextCallback = null as any
  public redirectPath = null as string | null
  public hasSaved = false

  beforeRouteLeave (to, from, next) {
    if (!this.hasSaved && !this.isReadOnly) {
      if (this.redirectPath == null) {
        this.redirectPath = to
      }
      this.showConfirm = true
      this.routeNextCallback = next
      return false
    } else {
      next()
    }
  }

  public beforeUnloadHandler (e) {
    if (!this.isReadOnly) {
      e.returnValue = 'Vos modifications ne sont peut-être pas enregistrées, voulez-vous vraiment quitter cette page ?'
    }
    return ''
  }

  public confirmCallback (value: boolean) {
    this.showConfirm = false
    if (value && this.routeNextCallback) {
      this.routeNextCallback()
    }
  }

  public beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeUnloadHandler)
  }

  public get isReadOnly () {
    return this.observation.isLinkedToInvoice
  }

  public mounted () {
    window.addEventListener('beforeunload', this.beforeUnloadHandler)
    this.ready = false
    const cachedDossier = sessionStorage.getItem('selectedDossier')
    if (Commons.isCachedDossierPatientValid(cachedDossier)) {
      this.selectedDossier = JSON.parse(cachedDossier!) as dossierPatientModel
      this.ensureUserNavTitle()
    } else {
      this.$router.push({ name: 'patient' })
    }
    this.subscriptions.push(this.podoService.podoObservationSelected$.subscribe((observation: PodiatryObservationModel) => {
      this.observation = observation
      this.getAllRelevantAppointments()
      this.ready = true
    }))
    this.userFullName = this.userService.fullName
  }

  private getAllRelevantAppointments () {
    this.agendaService.getAppointmentsForConsultation(this.selectedDossier?.guid, 5, this.observation.id).then((appointments) => {
      this.appointments = appointments
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.errorMessages = res.errors
    }).finally(() => {
      this.readyAppointments = true
    })
  }

  public ensureUserNavTitle () {
    if (this.selectedDossier?.guid) {
      Commons.updateDossierName()
    }
  }

  public get hasSelectedDossierValidOFAS () {
    if (this.selectedDossier) {
      return statusOFAS.find(o => o.value === this.selectedDossier.ofasStatusEnumId)
    }
    return false
  }

  public get getInfoText () {
    if (this.observation.id) {
      return "La saisie d'heures existante sera automatiquement adaptée pour cette observation."
    } else {
      return "Une saisie d'heures sera automatiquement créée pour cette observation."
    }
  }

  public async Save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isLoading = true
      const res = await this.podoService.AddPodoObservation(this.selectedDossier.guid, this.observation)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isLoading = false
        })

      if ((res as AxiosResponse<any>)?.status === 200) {
        this.hasSaved = true
        this.redirectPath = null
        this.returnToList()
      }
    } else {
      let merged = this.$refs
      if (this.$refs.evaluationInitialeRef) {
        const evaluationInitialeRefs = (this.$refs.evaluationInitialeRef as Vue).$refs
        merged = Object.assign(merged, evaluationInitialeRefs)
        for (const prop in evaluationInitialeRefs) {
          if (Object.prototype.hasOwnProperty.call(evaluationInitialeRefs, prop)) {
            if (prop.startsWith('Cell')) {
              merged = Object.assign(merged, (evaluationInitialeRefs[prop][0] as Vue).$refs)
            }
          }
        }
      }
      merged = Object.assign(merged, (this.$refs.rdvConcerné as Vue).$refs)
      Commons.focusFirstComponentWithError(observer, merged, 'Ref')
    }
  }

  public buttonBack () {
    this.redirectPath = null
    // act as if we saved, so that we bypass the confirm dialog
    this.hasSaved = true
    this.returnToList()
  }

  public returnToList (forceReset = false) {
    if (forceReset) {
      this.redirectPath = null
    }
    if (this.redirectPath == null) {
      this.$router.go(-1)
    } else {
      this.$router.push(this.redirectPath)
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public destroyed () {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe()
    })
  }
}
